import React from "react";

import "./styles.css";

import logo from "../../assets/img/logo.png";
import barIcon from "../../assets/img/menu_bar.png";

const Navbar = () => {
  return (
    <div className="sticky-top">
      <nav className="navbar navbar-expand-sm">
        <div className="container-fluid">
          <a className="navbar-brand" href="/">
            <img src={logo} alt="logo" className="logo_img" />
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#mynavbar"
          >
            <img src={barIcon} alt="bar icon" className="bar_icon" />
          </button>

          <div className="custom_spacer"></div>
          <div className="collapse navbar-collapse" id="mynavbar">
            <ul className="navbar-nav me-auto">
              <li className="nav-item">
                <a className="nav-link" href="/">
                  Home
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#gallery">
                  Gallery
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#expertise">
                  Expertise
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#about_us">
                  About Us
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#our_clients">
                  Our Clients
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#">
                  Products
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#projects">
                  Projects
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#contact">
                  Contact
                </a>
              </li>
            </ul>
            <a href="#contact" className="btn quote_btn">
              Get a Quote
            </a>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Navbar;
