import logo from "./logo.svg";
import "./App.css";
import Hero from "./components/Hero";
import Navbar from "./components/Navbar";
import MenuCard from "./components/MenuCard";
import Project from "./components/Project";
import Footer from "./components/Footer";
import Copyright from "./components/Copyright";
import GoogleMapLocation from "./components/GoogleMapLocation";
import ContactForm from "./components/ContactForm";
import LiveChat from "./components/LiveChat";

function App() {
  return (
    <div className="App">
      <Navbar />
      <Hero />
      <MenuCard />
      <Project />
      <ContactForm />
      <GoogleMapLocation />
      <Footer />
      <Copyright />
      <LiveChat />
    </div>
  );
}

export default App;
