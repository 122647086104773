import React from "react";

import "./styles.css";

const Hero = () => {
  return (
    <div className="hero_wrapper">
      <div className="coming-soon-wrapper vh-100">
        <div className="text-center coming-soon-content">
          <h1 className="title animated fadeIn">Zenith Automation Limited</h1>
          <p className="animated fadeIn delay-1s">
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy text
            ever since the 1500s, when an unknown printer took a galley of type
            and scrambled it to make a type specimen book. It has survived not
            only five centuries, but also the leap into electronic typesetting,
            remaining essentially unchanged.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Hero;
