import React from "react";
import "./styles.css";

const Footer = () => {
  return (
    <div className="info_section container p-5">
      <div className="row">
        <div className="col-md-4 address mt-3">
          <h3>Office</h3>
          <br />
          <p>
            <strong>Phone:</strong> +8801719460044
          </p>
          <p>
            <strong>Email:</strong> inquiry@zenithautomationltd.com
          </p>
          <p>
            <strong>Address:</strong> House-42, Road-07, Senpara Parbata,
            Mirpur-10, Dhaka-1216
          </p>
        </div>
        <div className="col-md-4 social text-center mt-3">
          {/* <!-- Social Media Links --> */}
          <a href="#" className="fa fa-facebook"></a>
          <a href="#" className="fa fa-linkedin"></a>
          <a href="#" className="fa fa-twitter"></a>
          <a href="#" className="fa fa-instagram"></a>
        </div>
        <div className="col-md-4 domain text-center mt-3">
          <h3>Zenith Automation Limited</h3>
        </div>
      </div>
    </div>
  );
};

export default Footer;
