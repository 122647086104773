import React from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import "./styles.css";

import ProjectSector from "../ProjectSector";

const Project = () => {
  const [value, setValue] = React.useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <h1 className="section_title mt-5" id="projects">
        Our Projects
      </h1>
      <div className="container">
        <Box sx={{ width: "100%", typography: "body1" }}>
          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList
                onChange={handleChange}
                aria-label="lab API tabs example"
              >
                <Tab label="All" value="1" />
                <Tab label="Completed" value="2" />
                <Tab label="Pending" value="3" />
              </TabList>
            </Box>
            <TabPanel value="1" className="tab_text">
              <ProjectSector sectorTitle="Pharmaceuticals" sectorId="1" />
              <ProjectSector sectorTitle="Garments and Textiles" sectorId="2" />
            </TabPanel>
            <TabPanel value="2" className="tab_text">
              <ProjectSector sectorTitle="Pharmaceuticals" sectorId="1" />
              <ProjectSector sectorTitle="Garments and Textiles" sectorId="2" />
            </TabPanel>
            <TabPanel value="3" className="tab_text">
              <ProjectSector sectorTitle="Pharmaceuticals" sectorId="1" />
              <ProjectSector sectorTitle="Garments and Textiles" sectorId="2" />
            </TabPanel>
          </TabContext>
        </Box>
      </div>

      <div className="text-center">
        <a href="#" className="btn btn-outline-dark">
          See more
        </a>
      </div>
    </>
  );
};

export default Project;
