import React from "react";
import SingleProjectCard from "../SingleProjectCard";

import "./styles.css";

const ProjectSector = (props) => {
  return (
    <>
      <h6 className="sector_title">{props.sectorTitle}</h6>
      <div className="project_list">
        <div className="container">
          {props.sectorId === "1" ? (
            <div className="row">
              <div className="col-md-3">
                <SingleProjectCard projectTitle="The ACME Laboratories Ltd" />
              </div>
              <div className="col-md-3">
                <SingleProjectCard projectTitle="Incepta Pharmaceuticals Ltd" />
              </div>
              <div className="col-md-3">
                <SingleProjectCard projectTitle="Renata Limited" />
              </div>
              <div className="col-md-3">
                <SingleProjectCard projectTitle="General Pharmaceuticals Ltd" />
              </div>
            </div>
          ) : props.sectorId === "2" ? (
            <div className="row">
              <div className="col-md-3">
                <SingleProjectCard projectTitle="Epyllion Knitwears Ltd." />
              </div>
              <div className="col-md-3">
                <SingleProjectCard projectTitle="4A Yarn Dyeing Ltd." />
              </div>
              <div className="col-md-3">
                <SingleProjectCard projectTitle="Green Textile Limited" />
              </div>
              <div className="col-md-3">
                <SingleProjectCard projectTitle="CA Knitwears Ltd." />
              </div>
            </div>
          ) : (
            <div>No projects available</div>
          )}
        </div>
      </div>
    </>
  );
};

export default ProjectSector;
