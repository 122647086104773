import React, { useState } from "react";
import "./styles.css";

const LiveChat = () => {
  const [allButtonDisplayClass, setAllButtonDisplayClass] = useState("d-block");
  const [triggerButtonDisplayClass, setTriggerButtonDisplayClass] =
    useState("d-block");

  return (
    <div className="live_chat_section_wrapper">
      <div className={allButtonDisplayClass}>
        <div className="all_btn">
          <a href="#" className="fa fa-whatsapp live_chat_icon"></a>

          <a href="#" className="fa fa-envelope live_chat_icon"></a>

          <a href="#" className="fa fa-instagram live_chat_icon"></a>
        </div>
      </div>

      <br />
    </div>
  );
};

export default LiveChat;
