import React from "react";
import "./styles.css";
const ContactForm = () => {
  return (
    <div className="container-fluid">
      <div className="row justify-content-center">
        <div className="col-lg-6 col-md-8 col-sm-12">
          <div className="contact-form-container" id="contact">
            <h2>Contact Us</h2>
            <form>
              <div className="mb-4">
                <label htmlFor="name" className="form-label">
                  Full Name
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="name"
                  placeholder="Enter your name"
                  required
                />
                <div className="form-text">Your full name is required.</div>
              </div>
              <div className="mb-4">
                <label htmlFor="email" className="form-label">
                  Email address
                </label>
                <input
                  type="email"
                  className="form-control"
                  id="email"
                  placeholder="Enter your email"
                  required
                />
                <div className="form-text">
                  We'll never share your email with anyone else.
                </div>
              </div>
              <div className="mb-4">
                <label htmlFor="message" className="form-label">
                  Message
                </label>
                <textarea
                  className="form-control"
                  id="message"
                  rows={4}
                  placeholder="Enter your message"
                  required
                  defaultValue={""}
                />
                <div className="form-text">
                  Please provide a detailed message.
                </div>
              </div>
              <button type="submit" className="btn btn-dark">
                Send Message
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactForm;
