import React from "react";
import "./styles.css";

const MenuCard = () => {
  return (
    <>
      <div className="container-fluid mt-5">
        <div className="row">
          <div className="col-md-4 single_menu_card">
            <img
              src="https://www.bivocom.com/wp-content/uploads/2023/04/%E5%9B%BE%E7%89%875.png"
              alt=""
            />
            <h1>Product</h1>
          </div>
          <div className="col-md-4 single_menu_card">
            <img
              src="https://zenatix.com/wp-content/uploads/Definition-of-building-management-system.jpg"
              alt=""
            />
            <h1>Project</h1>
          </div>
          <div className="col-md-4 single_menu_card">
            <img
              src="https://titanproducts.com/wp-content/uploads/2022/10/building-automation.jpg"
              alt=""
            />
            <h1>Resource</h1>
          </div>
        </div>
      </div>
    </>
  );
};

export default MenuCard;
