import React from "react";
import "./styles.css";

const SingleProjectCard = (props) => {
  return (
    <>
      <div className="single_project_card">
        <img
          src="https://static.thenounproject.com/png/2260571-200.png"
          alt=""
        />
        <br />
        <h6>{props.projectTitle}</h6>
      </div>
    </>
  );
};

export default SingleProjectCard;
